export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '',
    banner_img_root : '',
    resid : '',
    key_value : 'laschuladas',
    secret_value : 'laschuladas',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12266.280587696745!2d-105.03433833022461!3d39.771758599999984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5363b63684063803!2sChuladas!5e0!3m2!1sen!2sin!4v1663567441594!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Las Chuladas"

};
